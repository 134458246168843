import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { parseISO, isBefore } from "date-fns";

import CareerCard from "../CareerCard";
import Select from "../Inputs/Select";
import Button from "../Button";
import { useEffect } from "react";
import CareersStudioFilter from "../CareersStudioFilter";

const GetCareers = () => {
  const { allWorkableJob } = useStaticQuery(graphql`
    query GetCareers {
      allWorkableJob(limit: 100) {
        edges {
          node {
            id
            application_url
            benefits
            department
            description
            employment_type
            requirements
            shortcode
            title
            created_at
            department_hierarchy {
              id
              name
            }
          }
        }
      }
    }
  `);

  return allWorkableJob.edges;
};

const CareersArchive = ({
  heading,
  showFilters = true,
  showFirst: first = 8,
}) => {
  const [showFirst, setShowFirst] = useState(first);
  const [department, setDepartment] = useState("");
  const [studios, setStudios] = useState([]);
  const careers = GetCareers() || [];

  const studioNames = [
    "nDreams Studio",
    "Orbital",
    "Elevation",
    "Near Light",
    "nDreams Group",
  ];

  const filterCareers = () => {
    if (studios.length > 0 && !department) {
      return [
        ...new Set([
          ...careers.filter(({ node: { department_hierarchy } }) =>
            department_hierarchy.some(({ name }) => studios.includes(name))
          ),
        ]),
      ];
    } else if (studios.length <= 0 && department) {
      return [
        ...new Set([
          ...careers.filter(({ node: { department_hierarchy } }) =>
            department_hierarchy.some(({ name }) => department === name)
          ),
        ]),
      ];
    } else if (studios.length > 0 && department) {
      return [
        ...new Set([
          ...careers.filter(
            ({ node: { department_hierarchy } }) =>
              department_hierarchy.some(({ name }) => department === name) &&
              department_hierarchy.some(({ name }) => studios.includes(name))
          ),
        ]),
      ];
    } else {
      return careers;
    }
  };

  const filteredCareers = filterCareers() || [];

  const departments = [
    ...new Set(
      [
        ...new Set(
          careers.map(({ node: { department_hierarchy } }) =>
            department_hierarchy.map(
              ({ name }) => !studioNames.includes(name) && name
            )
          )
        ),
      ].flat()
    ),
  ].filter((item) => item !== false);

  const updateStudios = (studio) => {
    if (studios.includes(studio)) {
      let filterStudio = studios.filter((name) => {
        return name !== studio;
      });

      return setStudios(filterStudio);
    }

    if (studio === "") return setStudios([]);

    return setStudios([studio, ...studios]);
  };

  const _careers = [].concat(careers).sort(({ node: a }, { node: b }) => {
    return !isBefore(parseISO(a?.created_at), parseISO(b?.created_at)) ? -1 : 1;
  });

  return (
    <div
      id="careers-archive"
      className="py-12 md:py-16 xl:py-28 bg-white relative"
    >
      <div className="container mx-auto relative px-10">
        <div className="grid grid-cols-1 md:grid-cols-14">
          <div className="col-span-full xl:col-span-12 xl:col-start-2">
            <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between lg:mb-12 gap-4">
              <h2 className="flex-1 text-3xl xl:text-4xl text-black font-bold uppercase">
                {heading}
              </h2>
              <div className="mb-12 lg:mb-0 grid grid-cols-1 md:grid-cols-2 w-full max-w-[650px] gap-2 md:gap-6">
                {showFilters && (
                  <CareersStudioFilter
                    size="lg"
                    setStudios={updateStudios}
                    studios={studios}
                  />
                )}
                {showFilters && (
                  <Select
                    size="lg"
                    value={department}
                    setValue={setDepartment}
                    options={[
                      {
                        value: "",
                        label: "Select department",
                      },
                      ...departments.map((department) => ({
                        value: department,
                        label: department,
                      })),
                    ]}
                    className="flex-1 mt-8 md:mt-0 relative z-10"
                  />
                )}
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-8 md:mt-0">
              {_careers?.length > 0 && filteredCareers?.length > 0 ? (
                <>
                  {_careers.map(({ node: career }, i) => (
                    <>
                      <CareerCard
                        {...{
                          key: i,
                          hide:
                            (i >= showFirst &&
                              studios.length === 0 &&
                              department === "") ||
                            (
                              Array.from(filteredCareers).find(
                                ({ node: { id } }) => id === career?.id
                              ) || []
                            ).length === 0,
                          ...career,
                        }}
                      />
                    </>
                  ))}{" "}
                </>
              ) : (
                <p className="col-span-full w-full text-lg md:text-xl">
                  No open vacancies, please check back in the future.
                </p>
              )}
            </div>
            {showFirst < careers.length &&
            filteredCareers.length === careers.length ? (
              <div className="mt-8 md:mt-12 text-center">
                <Button
                  className="mx-auto hover:bg-black hover:border-black"
                  clickHandler={() => setShowFirst(showFirst + 8)}
                >
                  Load more
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareersArchive;
