import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import ReactMarkdown from 'react-markdown'

import LinkWithIcon from '../LinkWithIcon'
import CareerCard from '../CareerCard'

import './style.scss'

const GetAllCareers = () => {
    const {
        allWorkableJob: { edges },
    } = useStaticQuery(graphql`
        query getAllCareers {
            allWorkableJob(limit: 100) {
                edges {
                    node {
                        department
                        description
                        shortcode
                        title
                    }
                }
            }
        }
    `)

    return edges
}

const CareersSpotlight = ({
    heading,
    body,
    bodyLinkText,
    image,
    subheading,
    archiveLinkText,
    careers = [],
}) => {
    const shortcodes = JSON.parse(careers) || []
    const allCareers = (GetAllCareers() || []).filter(
        ({ node: career }) =>
            shortcodes.length === 0 ||
            shortcodes.indexOf(career?.shortcode) >= 0
    )

    return (
        allCareers.length > 0 && (
            <div className="careers-spotlight relative overflow-hidden">
                <div className="py-12 md:py-16 xl:py-28 relative z-20">
                    <div className="container mx-auto px-10">
                        <div className="grid lg:grid-cols-14">
                            <div className="sm:col-span-2 lg:col-span-7 xl:col-start-2 xl:col-span-6">
                                <h2 className="line-styled-heading mb-8 md:mb-12 text-5xl md:text-4xl lg:text-5xl xl:text-6xl text-secondary font-extrabold uppercase">
                                    <ReactMarkdown children={heading} />
                                </h2>
                                <p className="mb-8 md:mb-12 text-4xl md:text-3xl lg:text-4xl xl:text-5xl text-black font-extrabold uppercase">
                                    {body}
                                </p>
                                <LinkWithIcon
                                    color="mi-dark"
                                    to="/careers"
                                    text={bodyLinkText}
                                    icon="arrowRight"
                                />
                            </div>
                            <div className="hidden lg:flex items-center col-span-8 col-start-9">
                                <Img
                                    fluid={image.fluid}
                                    className="hidden md:block w-full relative"
                                    style={{ position: null }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-12 md:pb-16 xl:pb-28 relative z-20 border-t border-dashed border-gallery overflow-hidden">
                    <div className="container mx-auto px-10">
                        <div className="grid grid-cols-14">
                            <div className="col-span-full xl:col-span-12 xl:col-start-2">
                                <div className="flex justify-between items-center mb-8 md:mb-12">
                                    <h2 className="text-3xl xl:text-4xl text-black font-bold uppercase">
                                        {subheading}
                                    </h2>
                                    <LinkWithIcon
                                        color="mi-dark"
                                        to="/careers#careers-archive"
                                        text={archiveLinkText}
                                        icon="arrowRight"
                                        className="hidden md:flex"
                                    />
                                </div>
                                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 md:gap-4">
                                    {allCareers.map(
                                        ({ node: career }, i) =>
                                            i < 4 && (
                                                <CareerCard
                                                    {...{
                                                        key: i,
                                                        className:
                                                            'mt-6 md:mt-0',
                                                        ...career,
                                                    }}
                                                />
                                            )
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    )
}

export default CareersSpotlight
